.contentFooterContainer {
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
  url('../../assets/background/media-bg.jpg');
  background-position: center;
  background-size: cover;
}
.mediaPageContainer {
  width: 100%;
  padding: 12vh 20vh 20vh 20vh;
  color: white;
  font-weight: 300;
  font-family: 'Open Sans', sans-serif;
}

.articleImage {
  width: 100%;
}
.articleImage > img {
  width: 100%;
}
.mediaPageSideTitle {
  color: white;
  margin: 1rem;
  font-family: 'HussarBoldOutline', sans-serif;
  text-transform: uppercase;
  font-size: 4rem;
  font-weight: 700;
  writing-mode: vertical-rl;
  position: fixed;
  bottom: 0;
  top: 0;
  height: 600px;
  transform: rotate(180deg);
}

.mediaTabDescription {
  width: 75%;
  margin: 0 auto;
  margin-top: -20px;
  margin-bottom: 30px;
  font-family: 'Open Sans', sans-serif;
  font-style: italic;
}
.mediaTabDescription p {
  margin: 0;
}
.mediaPageNav {
  display: flex;
  justify-content: center;
  margin: 2rem auto 0 auto;
  padding: 2rem;
  width: max-content;
}
.mediaPageNav a {
  margin: 0;
  color: white;
  font-family: 'HussarBoldOutline', sans-serif;
  display: flex;
  width: 100%;
  align-items: center;
}
.mediaPageNav a > p {
  margin: 0;
}

.mediaPageNav a:hover {
  text-decoration: none;
  color: white;
  font-family: hussarbold;
}
.mediaActiveTab {
  font-family: 'HussarBold', sans-serif !important;
}
.logoTabContent {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.logoTabContent > div:nth-child(odd) {
  margin-bottom: 6rem;
}
.logoTabContent > div:nth-child(even) {
  margin-top: 6rem;
}

.itemImageContour {
  border: 0.5px solid white;
}
.itemName {
  position: absolute;
  bottom: 0;
  left: 25%;
  right: 25%;
}
/* .itemName:hover{
  animation: animateItemName 1s linear;
}

@keyframes animateItemName {
  0% {
    left: -100vw;
  }
  100% {
    left: 0;
  }
} */

.circleItemContainer {
  width: 250px;
  position: relative;
  filter: grayscale(1);
  height: 250px;
  overflow: hidden;
}
@keyframes colorOnHover {
  0% {
    filter: grayscale(1);
  }
  100% {
    filter: grayscale(0);
  }
}
.circleItemContainer:hover {
  animation: colorOnHover 1s linear;
  filter: grayscale(0);
}
.circleItemContainer img {
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.circleItemImage {
  z-index: 22;
  object-fit: contain;
  cursor: pointer;
}
.circleItemImage:hover {
  animation: zoomOnHover linear 300ms;
  transform: scale(1.3);
}
@keyframes zoomOnHover {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);
  }
}

.halfCircle1,
.halfCircle2 {
  filter: brightness(0.3);
}
.halfCircle1:hover {
  animation: circlesFadeOut linear 1s;
  opacity: 0;
}
.halfCircle2:hover {
  animation: circlesFadeOut linear 1s;
  opacity: 0;
}
@keyframes circlesFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.halfCircle1 {
  animation: circlesAnimation1 linear 30000ms infinite;
  z-index: 21;
}
.halfCircle2 {
  transform: rotate(270deg);
  animation: circlesAnimation2 linear 30000ms infinite;
  z-index: 20;
}
@keyframes circlesAnimation1 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes circlesAnimation2 {
  0% {
    transform: rotate(360deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.circlesContainer {
  width: 100%;
  height: 100%;
}
.backgroundCircle1 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: rgba(255, 255, 255, 0.3) 1px solid;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.backgroundCircle2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: rgba(255, 255, 255, 0.3) 1px solid;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: rotate(180deg);
}

.articlesListContainer {
  width: 80%;
  margin: 50px auto;
}


/* ARTICLES */
.articleItemContainer {
  cursor: pointer;
  position: relative;
  margin-bottom: 30px;
}
/* .visitButton {
  position: absolute;
  top: 0;
  left: 0;
} */
/* .articlesTabContent {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
} */
.articleItemImage {
  width: 100%;
}
.articleItemImage:hover {
  animation: zoomOnArticleImage 200ms ease-in;
  transform: scale(1.05);
  filter: saturate(20%) brightness(0.4);
}
@keyframes zoomOnArticleImage {
  0% {
    transform: scale(1);
    filter: saturate(100%) brightness(1);
  }
  100% {
    transform: scale(1.05);
    filter: saturate(20%) brightness(0.4);
  }
}
.articleTitle {
  /* width: 100px; */
  height: 50px;
  font-family: 'HussarBold', sans-serif;
  margin: 0 auto;
  font-size: 1.5rem;
  color: white;
  font-weight: 500;
}


@media (max-width: 1200px) {
  .mediaPageSideTitle {
    writing-mode: horizontal-tb;
    transform: rotate(0deg);
    margin: 0 auto;
    font-size: 2rem;
    position: unset;
    padding-top: 6rem;
    height: unset;
  }

  .mediaPageContainer {
    padding: 30px 0
  }

  .circleItemContainer {
    height: 90px;
    width: 90px;
  }
  .circlesContainer {
    height: 90px;
    width: 90px;
  }

  .itemName {
    font-size: 10px
  }

  .logoTabContent {
    width: 90%;
    margin: 40px auto;
    margin-top: 100px;
  }
}
