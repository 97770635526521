.videoIntro {
  position: fixed;
  top: 0;
  right: 0;

  /* height: 100vh; */
  width: 100vw;
  height: 0vh;

  /* animation: videoIntroFadeOut 4000ms linear; */
  /* animation: videoGoesTop 4000ms linear; */
  animation: videoContainerGoesTop 4000ms linear;
  overflow: hidden;
  background: #151515;
  z-index: 25;
}

.videoIntro video {
  top: -200vh;
  z-index: 25;
  left: 0;
  right: 0;
  position: absolute;
  height: 80vh !important;
  animation: videoGoesTop 4000ms linear;
}
.videoElement {
  /* width: 100vh !important; */
}
@keyframes videoGoesTop {
  0% {
    top: 0;
    opacity: 1;
  }
  60% {
    top: 0;
    opacity: 1;
  }
  80% {
    top: 0;
    opacity: 1;
  }
  90% {
    top: -200vh;
    opacity: 1;
  }
  100% {
    top: -200vh;
    opacity: 0;
  }
}
@keyframes videoContainerGoesTop {
  0% {
    height: 100vh;
    opacity: 1;
  }
  60% {
    height: 100vh;
    opacity: 1;
  }
  90% {
    height: 100vh;
    opacity: 1;
  }
  100% {
    height: 100vh;
    opacity: 0;
  }
}
/* @keyframes videoIntroFadeOut {
  0% {
    height: 100vh;
  }
  80% {
    height: 100vh;
  }
  100% {
    height: 0vh;
  }
} */
