.projectContainer {
  width: 100%;
  background: no-repeat url('../../assets/background/background_realisation.jpg');
  background-size: cover;
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.pageTitle {
  margin-left: 10px;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  position: fixed;
  left: 0;
  font-family: HussarBold;
  /* font-size: 60px; */
  font-size: 3vw;
  z-index: 2;
  color: #f4f4f4;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 80%;
  margin-top: auto;
  margin-bottom: auto;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.headerTitle {
  font-size: 1rem;
  color: white;
  font-weight: 900;
  font-family: hussarbold;
  text-transform: uppercase;
  width: 100%;
}

.title {
  font-family: HussarBold;
  font-size: 1.5rem;
}
.subtitleClientsContainer {
  font-family: HussarBoldOutline;
  display: flex;
  justify-content: center;
}
.subtitleClient:not(:last-child):after{
  content: "\00a0-\00a0";
}
.location,
.date {
  font-size: 16px;
  color: white;
  font-family: 'open sans';
}

.photo {
  width: 100%;
}

.banner {
  object-fit: contain;
  width: 100vw;
  /* height: 33.33vh; */
}

.eventDescriptionContainer {
  color: gray;
  font-family: 'open sans';
  width: 80%;
  margin: 3rem auto 3rem auto;
}

.clientDetailsContainer {
  display: flex;
  width: 70%;
  justify-content: space-evenly;
  align-items: center;
  margin: 3rem auto;
}
.clientDetails {
  width: 33%;
}

.mediaContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40vh;
  margin: 0 auto;
}
.mediaContainer > div {
  width: 33.33%;
  height: 100%;
}

.projectPhotoContainer {
  filter: brightness(100%);
}

.projectPhotoContainer img {
  object-fit: cover;
  height: 100%;
}
.clientPhotoContainer {
  filter: brightness(100%);
}
.clientPhotoContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mediaVideo {
  filter: brightness(99%);
}

.separatorContainer {
  height: 100%;
  width: 10%;
  font-size: 10rem;
}
.separatorContainer span {
  height: 60%;
  border-left: 1px solid white;
  width: 10%;
}

.clientDescription {
  height: 60%;
  margin: auto 0;
  color: white;
  font-size: 16px;
  font-family: 'open sans';
  text-align: left;
  width: 60%;
}
.clientDescription p {
  width: 70%;
}

.spanDescription {
  color: white;
  font-size: 18px;
}

.eventDescription {
  text-align: center;
  font-size: 16px;
  border-bottom: 1px solid white;
  padding-bottom: 40px;
}
.spanRessources {
  color: white;
  font-size: 1.3rem;
  font-weight: 700;
  padding: 0.7rem;
  margin: 2rem;
}

.eventRessources {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ressourceItemContainer {
  margin: 0.4rem;
}
.ressourcesContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.ressourceItem {
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  padding: 0.5rem 1.4rem;
  font-weight: 700;
  margin: 0;
}
.ressourceItem:hover {
  background-color: rgba(255, 255, 255, 0.35);
  transition: background-color 500ms;
  user-select: none;
}


.allProjectsContainer {
  margin: 0 auto;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  font-size: 40px;
  font-family: HussarBold;
  color: white;
}

.allProjectsSpan {
  font-family: HussarBoldOutline;
}

.allProjectImage {
  height: 35px;
  margin: 8px 30px;
}

/* # # # # # # # # # # # */
@media (max-width: 1200px) {
  .banner {
    object-fit: cover;
  }
  .eventDescriptionContainer {
    width: 100%;
  }
  .clientDetails {
    flex-direction: column;
    margin: 0 auto;
  }
  .separatorContainer {
    display: block;
    width: 100%;
  }
  .separatorContainer > span {
    display: block;
    width: 80%;
    border-left: none;
    border-bottom: 1px solid white;
    margin: 0 auto;
  }
  .clientDescription {
    width: 100%;
    padding: 3rem 1rem;
    text-align: center;
  }
  .clientDescription > p {
    width: 100%;
  }
  .allProjectsContainer {
    font-size: 1.5rem;
  }
  .allProjects {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .voirLesProjets {
    margin: 0;
  }
  .allProjectImage {
    margin: 1rem;
    width: 2rem;
  }
  .mediaContainer {
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: scroll;
    cursor: grab;
  }
  .mediaContainer > div {
    width: 100%;
    margin: 0.5rem;
  }
  .clientPhotoContainer img {
    width: 80vw;
  }
}
