.pageContainer {
  width: 100%;
  height: 100%;
  min-height: 110vh;
  /* background: no-repeat, linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url('../../assets/background/background_realisation.jpg');
  background-size: cover; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footerContainer {
  z-index: 1;
  padding-top: 100px;
}
.realisationEventCard {
  display: block;
}
.realisationEventCard:last-child {
  margin-left: 0;
}
.tabsContainer {
  width: 100%;
  font-family: HussarBold;
  color: #f4f4f4;
  display: flex;
  justify-content: center;
  margin: 9rem auto 0 auto;
}

.tab {
  font-family: HussarBold;
  cursor: pointer;
  color: #585858;
}

.clientTab {
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
}

.activeTab {
  border-bottom: 5px solid grey;
  color: white;
}

.activeTab,
.tab:hover {
  font-family: HussarBold;
  cursor: pointer;
}

.loader {
  font-size: 3rem;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cardContainer {
  width: 70vw;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr)); */
}
.cardContainer > div {
  margin: 0 auto;
  width: max-content;
  height: 100%;
}
.activePartner {
  cursor: pointer;
  color: #f4f4f4 !important;
  text-decoration: underline;
}

.partner {
  cursor: pointer;
}
.partner:hover {
  color: #f4f4f4;
  text-decoration: underline;
}

.introduction {
  color: white;
  font-style: italic;
  margin: 3rem;
  font-family: 'open sans';
  font-weight: 300;
}
.introduction > p {
  margin: 0;
}

.separator {
  margin: 0 0.5rem;
  color: white;
}


.pageTitle {
  margin-left: 10px;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  position: fixed;
  left: 0;
  font-family: HussarBold;
  font-size: 3rem;
  z-index: 2;
  color: #f4f4f4;
}
.partnersList {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 80vw;
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); */
  color: gray;
  text-transform: uppercase;
}
.partnerTitleContainer {
  /* width: 100%; */
  display: flex;
  justify-content: center;
}
.partnerTitle {
  margin: 0;
}
/* .partnersList::before {
  display: block;
  content: '';
  font-weight: bold;
  height: 0;
  overflow: hidden;
  visibility: hidden;
} */
.partnerSeparator {
  margin: 0 1rem;
}
/* .partnersList > div:not(:first-child)::before {
  content: '·';
  padding-left: 1rem;
  padding-right: 1rem;
  cursor: default;
} */

.titleSpan {
  font-family: HussarBoldOutline;
}

.realisationsContent {
  z-index: 19;
}
/* .pageContainer > div:last-child {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
} */
.tabsContainerMobile {
  width: 100%;
}
.tabsSelectorMobile {
  width: 100%;
}
.tabsSelectorMobile > p {
  margin: 0 auto;
  width: max-content;
  color: white;
  font-family: open sans;
}
.mobileTab {
  width: 100%;
  padding: 1rem 0;
  background-size: cover;
  margin: 1px;
  color: white;
  filter: grayscale(100%) brightness(50%);
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.mobileTab > p {
  margin: 0 auto;
  font-size: 0.7rem;
}
.activeTabMobile {
  color: white;
  filter: grayscale(0%) brightness(100%);
  border: white 1px solid;
}
.mobileFirstRow {
  display: flex;
  justify-content: space-around;
}
.mobileFirstRow > div, .mobileSecondRow > div {
  width: 32%;
}
.mobileSecondRow {
  display: flex;
  justify-content: space-evenly;
}
.tabsContentMobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: #151515;
}
.mobileTabAllRealisations, .mobileTabAllClients {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 99.5vw;
}
.mobileTabAllRealisations > p, .mobileTabAllClients > p {
  margin: 0 1rem;
  font-size: 0.7rem;
}

.realisationsMobileBackgroundImg {
  position: fixed;
  top: 0;
  left: 0;
  object-fit: cover;
  transform: rotate(180deg);
  width: 100vw;
}

.pageContainer:last-child {
  z-index: 29;
}
.wheelContainer {
  height: 100%;
  width: 100%;
  background: red;
  background:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}
.wheelContainer > ul {
  perspective: 1000px;
}
/* # # # # # # # # # # # */
@media (max-width: 1200px) {
  .pageContainer {
    height: 100%;
    padding-top: 9rem;
    /* background-position: right !important;
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url('../../assets/background/background_realisation.jpg') right !important;
    background-size: cover !important; */
    /* background: none; */
    width: 100vw;
    min-height: 150vh;
  }
  .realisationsMobileBackgroundImg {
    height: 120%;
  }
  .pageTitle {
    writing-mode: horizontal-tb;
    transform: rotate(0deg);
    margin: 0 auto;
    font-size: 2rem;
    position: absolute;
    right: 0;
  }
  .introduction {
    font-size: 0.8rem;
    margin: 0 1rem;
  }
  .tabsContainer {
    width: 100%;
    height: 20vh;
    overflow-y: scroll;
    margin: 2rem 0;
    flex-wrap: wrap;
    padding: 0.5rem;
  }
  .activeTab {
    height: max-content;
  }
  .realisationsContent {
    height: 100%;
    padding-top: 5rem;
    z-index: 19;
  }
  .cardContainer {
    width: auto;
    margin-top: 7rem;
    /* height: 100%; */
    justify-content: center;
  }
  .cardContainer > div {
    margin: 1rem;
  }
  .realisationEventCard {
    height: 45%;
  }
  .realisationEventCard > div {
    height: 75vw !important;
    width: 40vw !important;
    justify-content: space-between;
  }
}
