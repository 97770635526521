.pageContainer {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.background1 {
  position: relative;
  width: 100%;
  height: 100vh;
  background: no-repeat, linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url('../../assets/background/background_vision_1.jpg');
  padding-top: 120px;
  /* background-size: cover; */
  background-attachment: fixed;
  overflow: hidden;
}

.topImagesContainer {
  display: flex;
  justify-content: space-between;
  width: 100vw;
  position: relative;
  height: 100%;
}
.image1,
.image2 {
  width: 52vw;
  height: 50%;
  object-fit: cover;
}
.image1 {
  position: absolute;
  top: 0;
  right: 0;
}
.image2 {
  position: absolute;
  bottom: 0;
  left: 0;
}
.titleContainer {
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  top: 50%;
  z-index: 10;
}

.title {
  font-size: 84px;
  font-family: HussarBoldOutline;
  color: white;
}

.dot {
  font-family: HussarBold;
  font-size: 84px;
  color: white;
}
.spanTitle {
  font-family: HussarBold;
}

.background2Desktop {
  background: no-repeat, linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url('../../assets/background/background_vision_2.jpg');
  background-attachment: fixed;
  background-position: 50% 0%;
  background-size: 100%;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.footerContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.scrollGrid {
  padding-top: 100px;
  position: relative;
  display: none;
}

.gridContent {
  display: flex;
  width: 85%;
  margin-left: 10%;
}
.visionSideTitleContainer {
  writing-mode: tb-rl;
  font-family: hussarbold;
  height: fit-content;
  position: absolute;
  top: 10%;
  left: 0;
  color: rgba(255, 255, 255, 0.1);
  transform: rotate(180deg);
}
.visionSideTitle {
  font-size: 4.3rem;
  margin: 1rem;
  color: white;
}
/* # # # # # # # */
/* # # GRID TABS */
/* # # # # # # # */

.gridTab1 {
  align-items: flex-start;
}
.gridTab2 {
  align-items: center;
}
.gridTab3 {
  align-items: flex-end;
}
.gridSeparator {
  border-left: 2px solid white;
}

.gridContainerDesktop {
  align-items: center;
  display: flex;
  height: 600px;
  justify-content: center;
  margin: 0 auto;
  padding: 4rem 0;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  width: 70%;
}

.gridTitleContainer {
  width: 30%;
  text-align: right;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  height: 100%;
  align-items: flex-end;
  margin-left: 3rem;
}

.gridTitle {
  display: flex;
  /* flex-direction: column; */
  font-family: HussarBold;
  opacity: 0.3;
  color: #585858;
  border-right: 2px solid white;
  padding-right: 30px;
  width: max-content;
  height: 100%;
}
.gridTitle > h3 {
  margin: 0 auto;
  cursor: pointer;
  font-size: 2.5rem !important;
}

.gridTitleSelected {
  font-family: HussarBold;
  color: white;
  animation: gridTitleSelectedFadeIn 1s linear;
  opacity: 1;
}
@keyframes gridTitleSelectedFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* # # # # # # # */
/* # # GRID TEXT */
/* # # # # # # # */

.gridTextContainer {
  width: 65%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.gridText {
  margin-left: 5%;
  color: #bebebe;
  text-align: left;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-family: 'open sans';
  font-weight: 300;
}
.gridText > p {
  margin: 0;
}
.animateGridText {
  animation: animateGridText linear 400ms;
}
@keyframes animateGridText {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.gridTextSpan {
  color: white;
  font-weight: 600;
}

.gridTextHidden {
  display: none;
  opacity: 0;
}

.gridAnchors {
  position: absolute;
  height: 10px;
  width: 90vw;
  background: salmon;
  margin: auto;
}
.gridAnchor1 {
  top: 5%;
}
.gridAnchor2 {
  top: 0;
  bottom: 0;
  margin: auto;
}
.gridAnchor3 {
  bottom: 5%;
}


.background3 {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.showreelContainer {
  position: relative;
  width: 100%;
  height: 100%;
}
.plateauBleu {
  background: no-repeat, linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../../assets/background/vision-plateau-bleu.jpg');
  background-position: 50% 50%;
  background-size: cover;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 100vh;
  overflow: hidden;
}
.plateauBleuLabel {
  color: white;
  font-size: 1.5rem;
  width: 100%;
  z-index: 25;
  text-align-last: right;
  position: absolute;
  bottom: 0;
  padding: 2rem;
}
.plateauBleuLabel p {
  margin: 0;
  text-transform: uppercase;
  font-family: hussarboldoutline;
}
.plateauBleuLabel p > span {
  font-family: hussarbold;
}

.plateauBleuHeaders {
  position: absolute;
  top: 5%;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-family: hussarbold;
  color: white;
  align-items: center;
  font-size: 2rem;
  width: 100%;
  height: 70vh;
}
.pbhLeft, .pbhMid, .pbhRight {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.pbhLeft {
  justify-content: flex-end;
}
.pbhMid {
  justify-content: center;
}
.pbhRight {
  justify-content: flex-start;
}
.contactFooter {
  color: white;
  font-size: 1rem;
  font-family: hussarbold;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
}
.contactFooter img {
  width: 2.5rem;
}

/* # # # # # # # # # # # */
/* MOBILE GRID CONTAINER */
/* # # # # # # # # # # # */
.background2Mobile {
  background: no-repeat, linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url('../../assets/background/background_vision_2.jpg');
  background-size: cover;
  width: 100%;
  height: 300vh;
  position: relative;
  background-position: right;
}

.gridContainerMobile {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 10vh;
  width: 100%;
}
.gridContentMobile {
  padding: 2rem 1rem 1rem 1rem;
}
.gridTitleMobile {
  color: white;
  text-align: left;
  padding: 1rem;
  font-family: hussarboldoutline;
}
.gridTitleMobile > h3 {
  font-size: 1.4rem;
}
.activeTitleMobile > h3 {
  font-family: hussarbold;
}
.gridTextContentMobile {
  font-size: 0.8rem;
  padding-left: 2rem;
}
.tileMobile {
  border-left: 1px solid rgb(180, 180, 180);
}
.activeTileMobile {
  border-left: white 2px solid;
}
.gridTextMobile {
  margin-left: 5%;
  color: rgb(182, 182, 182);
  text-align: left;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-family: 'open sans';
  font-weight: 300;
}

.downloadButtonP {
  height: 2rem;
}
.buttonElement {
  position: absolute;
  display: block;
  width: 230px;
  height: 42px;
  color: #ffffff;
  font-size: 14px;
  line-height: 200%;
  text-decoration: none;
  text-align: center;
  z-index: 1;
  font-family: hussarbold;
}
.buttonElement span {
  position: absolute;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  z-index: 1;
}
.buttonElement .borderDLButton {
  border: 1px solid #ffffff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
.buttonElement .topDLButton, .buttonElement .bottomDLButton, .buttonElement .leftDLButton, .buttonElement .rightDLButton {
  background-color: rgb(255, 255, 255);
  height: 1px;
  width: 0;
}
.buttonElement .leftDLButton, .buttonElement .rightDLButton {
  height: 0;
  width: 1px;
}
.buttonElement .topDLButton {
  top: -5px;
  left: -15px;
}
.buttonElement .bottomDLButton {
  bottom: -5px;
  right: -15px;
}
.buttonElement .leftDLButton {
  top: -15px;
  right: -5px;
}
.buttonElement .rightDLButton {
  bottom: -15px;
  left: -5px;
}
.buttonElement:hover {
  color: #fff;
}
.buttonElement:hover .borderDLButton {
  top: -5px;
  bottom: -5px;
  left: -5px;
  right: -5px;
}
.buttonElement:hover .topDLButton, .buttonElement:hover .bottomDLButton {
  width: 230px;
}
.buttonElement:hover .leftDLButton, .buttonElement:hover .rightDLButton {
  height: 40px;
}
.buttonElement:hover .topDLButton {
  left: -5px;
}
.buttonElement:hover .bottomDLButton {
  right: -5px;
}
.buttonElement:hover .leftDLButton {
  top: -5px;
}
.buttonElement:hover .rightDLButton {
  bottom: -5px;
}
.buttonElement .textDLButton {
  position: relative;
  z-index: 1;
  margin: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: white;
}

/* # # # # # # # # # # # */
@media (max-width: 1200px) {
  .titleContainer {
    top: 53%;
    left: -50%;
  }
  .titleContainer > * {
    font-size: 2rem;
  }
  .background1 {
    /* height: 100%; */
    height: 125vh;
    background-size: cover;
    /* padding: 1rem; */
  }
  .topImagesContainer {
    /* height: 110vh; */
    height: 100%;
    margin: 1rem;
  }

  .image1 {
    top: 0;
    left: 20%;
    right: 0;
  }
  .image2 {
    bottom: 0;
    left: -100%;
    right: 0;
  }
  .image1, .image2 {
    margin: 0 auto;
    border: gray 2px solid;
    max-height: 50vh;
    width: 130vw;
  }
  /* //SHOWREEL */
  .plateauBleuHeaders {
    font-size: 0.8rem;
    height: 40vh;
  }
  .pbhLeft {
    text-align: left;
  }
  .pbhMid {
    text-align: center;
  }
  .pbhRight {
    text-align: right;
  }
  .plateauBleuLabel {
    font-size: 1rem;
    top: 50%;
    bottom: "";
    text-align: right;
  }
}
