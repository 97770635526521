.closeSelector {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
}

.contactPageSideTitle {
  color: white;
  margin: 1rem;
  font-family: 'HussarBoldOutline', sans-serif;
  text-transform: uppercase;
  font-size: 4rem;
  font-weight: 700;
  height: fit-content;
  writing-mode: vertical-rl;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  transform: rotate(180deg);
}

.backgroundContainer {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../../assets/background/contactpage.jpg');
  background-position: center;
  background-size: cover;
  min-height: 120vh;
  position: relative;
}
.contactPageContainer {
  height: 100%;
  width: 100%;
  padding: 16vh 20vh 20vh 20vh;
  color: white;
  font-weight: 300;
  font-family: 'Open Sans', sans-serif;
}
.contactPageFormDescription {
  font-style: italic;
  width: 75%;
  margin: 0 auto 2rem auto;
}
.contactPageFormDescription p > span {
  font-weight: 700;
}

.selectorContainer {
  max-width: max-content;
  padding: 1rem;
  margin: 0 auto;
}

.selectorSendTo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  max-width: max-content;
  padding: 0 0 0.5rem 0;
  position: relative;
}
.selectorSendTo p:first-of-type {
  margin-right: 3.5rem;
  font-weight: 300;
  color: lightgray;
}
.selectorSendTo p {
  margin: 0;
}
.selectorSendTo img {
  width: 0.6rem;
  margin-top: 0.25rem;
}
.selectorTitle {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid white;
  font-weight: 300;
  cursor: pointer;
  padding-bottom: 0.5rem;
}
.selectorOptions {
  position: absolute;
  top: 100%;
}
.selectorOptions input {
  background: rgba(255, 255, 255, 0.4);
  border: none;
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-align: left;
  margin-bottom: 0.15rem;
}
.selectorOptions input:hover {
  background: black;
  color: white;
}
.contactPageForm {
  margin-top: 2rem;
  margin-bottom: 4rem;
}
.contactPageFormInputs {
  margin-top: 3rem;
  margin-bottom: 5rem;
}
.contactPageFormInputs input {
  background: transparent;
  color: white;
  outline: none;
  border: none;
  border-bottom: solid 1px white;
  margin: 1rem;
  font-weight: 300;
}
.contactPageFormInputs input:focus {
  outline: none;
}

.formTextArea {
  margin: 1rem;
  display: flex;
  border-bottom: 1px white solid;
}
.formTextArea input {
  width: 100%;
  background: transparent;
  color: white;
  border: none;
}
.formTextArea input:focus {
  outline: none;
  margin: 1rem;
  animation: inputMargin 200ms ease-in-out;
}

@keyframes inputMargin {
  0% {
    margin: 0;
  }
  100% {
    margin: 1rem;
  }
}

.formTextArea svg {
  font-size: 2rem;
}
.arrowLogoStyle {
  display: flex;
  justify-content: center;
}
.arrowLogoStyle img {
  width: 2rem;
}
.formTextArea p {
  margin: 0;
}
.formTextArea textarea {
  border: none;
  width: 100%;
  outline: none;
  color: white;
  height: 100%;
  padding: 1rem;
  overflow: auto;
  max-height: 30vh;
}
.textAreaMessage {
  background: transparent;
}
.fadeInSelector {
  animation: fadeInSelector 1000ms ease;
}
@keyframes fadeInSelector {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.contactPageFormSubmit {
  background: transparent;
  outline: none;
  border: none;
  margin: 0.5rem;
}

.mediaPageNav {
  display: flex;
  justify-content: center;
  margin: 2rem auto 0 auto;
  padding: 2rem;
}
.mediaPageNav a {
  margin: 0;
  color: white;
  font-family: 'HussarBoldOutline', sans-serif;
  display: flex;
  width: 100%;
  align-items: center;
}
.mediaPageNav a > p {
  margin: 0;
}
.mediaPageNav a:hover {
  text-decoration: none;
  color: white;
  font-family: hussarbold;
}

.mediaActiveTab {
  font-family: 'HussarBold', sans-serif !important;
}

.backgroundContainer > div:last-child {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.meetingContainer {
  /* height: 100%; */
}


/* # # # # # # # # # # # */
@media (max-width: 1200px) {
  .backgroundContainer {
    padding-top: 7rem;
    min-height: 120vh;

    overflow: hidden;
  }
  .contactPageMobileTitle {
    font-size: 2rem;
    color: white;
  }
  .contactPageContainer {
    padding: 1rem;
  }
  .contactPageFormDescription {
    width: 100%;
    font-size: 0.8rem;
  }
  .mediaPageNav {
    margin: 0 auto;
    padding: 0;
  }
  .mediaActiveTab {
    text-decoration: underline;
  }
  .selectorContainer {
    font-size: 0.8rem;
    padding: 1rem 0;
  }


  .contactPageFormInputsMobile {
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
  .contactPageFormInputsMobile input {
    background: transparent;
    color: white;
    outline: none;
    border: none;
    border-bottom: solid 1px white;
    margin: 0;
    font-weight: 300;
    width: 30%;
  }
  .mobileInputsTopRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5rem;
  }
}
