.membersContainer {
  width: 100%;
  height: 100vh;
  position: relative;
  /* background: url(../../assets/background/SOUS_COUCHE_V2.png);
  background-size: 100%; */
}

.sideTitle {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  position: absolute;
  right: 0;
  font-family: HussarBoldOutline;
  font-size: 8vh;
  height: 100vh;
  color: white;
}

.span {
  font-family: HussarBold;
  color: white;
}
.squadContainerDesktop {
  padding-top: 100px;
  margin: 0 auto;
  /* display: flex; */
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  height: 100vh;
}

.membersFirstRow {
  color: initial;
}
.membersSecondRow {
  color: initial;
}
.squadRow {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}


.mobileMembersBoard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* # # # # # # # # # # # */
@media (max-width: 1200px) {
  .membersContainer {
    height: 100%;
  }

  .sideTitle {
    writing-mode: horizontal-tb;
    left: 0;
    margin: 1rem auto 0 auto;
    font-size: 1.8rem;
    height: max-content;
  }
  .squadContainerMobile {
    padding-top: 5rem;
  }
}